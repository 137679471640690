<template>
  <div class="container text-primary">
    <div class="row">
      <div class="col-md-6 mx-auto">
        <div class="card border-0 shadow-sm bg-light">
          <div class="card-body">
            <div v-if="!error">
              <div class="row mb-4">
                <div class="col-auto mx-auto">
                  <loading></loading>
                </div>
              </div>

              <h4 class="fw-bold">Attempting login using token</h4>
            </div>

            <!--  -->
            <div class="row text-center" v-if="error">
              <div class="col">
                <h4 class="text-danger fw-bold mb-4">
                  There seems to have been an error, perhaps you are using an
                  expired token
                </h4>
                <router-link
                  class="btn btn-outline-primary"
                  to="/login"
                  v-if="error"
                  ><i class="far fa-redo me-2"></i>Try again</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: false,
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
  },
  methods: {
    acceptLoginToken() {
      this.$store.dispatch("auth/login", this.$route.params.token);
    },
    // acceptLoginToken() {
    //   if (this.token) {
    //     this.$axios
    //       .post(process.env.VUE_APP_API_URL + "/accept-login-token", {
    //         token: this.token,
    //         requester: "practitioners-portal",
    //       })
    //       .then(({ data }) => {
    //         if (data.error == "invalid-credentials") {
    //           this.error = true;
    //         } else if (data.error == "tfa_required") {
    //           window.location = data.url;
    //         } else {
    //           this.$store.getters['auth/user'] = data.user;
    //           this.$stash.token = data.token;

    //           localStorage.setItem("user", JSON.stringify(data));
    //           if (data.token) {
    //             this.$axios.defaults.headers.Authorization = `Bearer ${data.token.access_token}`;
    //           }

    //           this.$router.push({ name: "DashboardView" });
    //           // this.$stash.dispatch("login", data).then(() => {});
    //         }
    //       });
    //   }
    // },
  },
  mounted() {
    this.acceptLoginToken();
  },
};
</script>

<style>
</style>